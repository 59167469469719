.header {
	background-color:#6f172d;
	padding-top: 10px;
	padding-bottom: 10px;

}

.me {
	background-color:#ccc;
	display:inline-block;
	cursor:pointer;
	padding:20px;
	margin-right:20px;
}
.me-dropdown {
	width:170px;
	.nav-icon {
		font-size: 20px;
    	margin-left: 6px;
	}
	.MuiListItemIcon-root {
		min-width: 30px;
	}
}
.logout-actions{
	justify-content: center !important;
}
.me-col {
	text-align:right;
}