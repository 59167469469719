@import "./scss/variables";
@import "./scss/buttons";
/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
body {
	background-color:#81253c;
}
.logged-in-with-header {
	padding-top:100px;
}
.loginBox {
	margin-left:80px;
	margin-right:80px;
	border: solid 1px #ccc;
	padding:10px;
	h3.loginhdr {
		font-weight:normal;
	}
}
.blue-btn {
	background-color:#73b47a;
}
.login-form.dashboard {
	max-width:initial;
	margin:0px 50px;
	margin-bottom:50px;
	.logo-wrapper {
		max-width:400px;
		margin:auto;
	}
	h1 {
		font-size:40px;
		font-weight:normal;
		margin-bottom:20px;
	}
}
*, *:before, *:after {
  box-sizing: inherit;
}
a {
	color: #347c68;
}
.App {
  text-align: center;
  height:600px;
  font-family: $fontFamily;
  font-weight:300;
}
.page-wrapper {
	padding-top:1px;
}
.logo-wrapper{
	margin-bottom:20px;
	margin-left:100px;
	margin-right:100px;
	img{
		width:100% !important;
		height:auto;
	}
}
.required-note {
	font-size:14px;
	text-align:left;
	margin-bottom:20px;
}

.page-content {
	position:relative;
	margin-top:50px;
	h1{
		font-size:30px;
		margin:auto;
		color:#153863;
		line-height:40px;
		font-weight:300;
		text-transform:uppercase;
	}
	.form {
		margin-top:20px;
	}
}
.login-form.login-page { 
	margin-bottom:50px;
}
h1.welcome {
	font-size:30px;
	max-width:400px;
	margin:auto;
	color:#4f0f25;
	line-height:40px;
	font-weight:300;
	text-transform:uppercase;

}

h2.welcome-subheader{
	margin-top:40px !important;
	max-width:400px;
	margin-left:auto;
	margin-right:auto;
}

.form-row{
	margin-bottom:20px;
}
.form-row-left {
	text-align:left;
}
.register-wrapper {
	margin-top:30px;
	
	max-width:400px;
	margin-left:auto;
	margin-right:auto;
	.btn {
		width:100%;
		margin-bottom:30px;
	}
}
.back-link{
	position:absolute;
	top:-20px;
	text-decoration:none;
	font-size:12px;
}
.login-form{
	border:solid 6px #b5913d;
	background-color:#fff;
	max-width:600px;
	margin:auto;
	padding:20px;
	margin-top:20px;
	.required-note{
		text-align:center;
	}
	&.register-form {
		margin-top:20px;
	}
	h2{
		font-weight:300;
		text-transform:uppercase;
		text-align:center;
		margin-top:0px;
	}
	.error {
		color:#900;
		text-align:left;
		margin-bottom:20px;
		font-size:11px;
	}
}
.error {
	color:#900;
	text-align:left;
	margin-bottom:20px;
	font-size:11px;
}
.form-btns{
	margin-top:20px;
}
input:read-only {
  background-color: #efefef;
  cursor: none;
  pointer-events: none;
}
.read-only-row {
  cursor: none;
  pointer-events: none;
	
}

@import "./scss/header";
@import "./scss/admin";
@import "./scss/screens/my-account";
@import "./scss/screens/reports";
@import "./scss/screens/tasting";

.MuiButton-containedPrimary {
	background-color:red;
}
