.login-form.dashboard {
	.admin {
		&.questions {
			h1 {
				font-size:40px;
			}
		}
		h1 {
			font-size:30px;
			text-transform:capitalize;
		}
		.page-content{
			margin-top:20px;
		}
	}
}
.admin {
	.judge{
		font-size:10px;
		font-weight:bold;
		background-color:#333;
		color:#fff;
		padding:5px 10px;
		margin:5px;
		display:inline-block;
		border-radius: 50vh;
		&.active{
			background-color:#00C;
		}
	}
	.page-content {
		text-align:left;
	    margin-left: auto;
	    margin-right: auto;
	}
	.actions{
		float:right;
	}
	.delete-btn{
		background-color:#900;
		width:100%;
	}
	.cancel-btn {
		background-color:#fff;
		border:solid 1px #ccc;
		width:100%;
	}
	.add-tasting-btn{
		margin:auto;
		background-color:#fff;
		width:50%;
	}
	

	.wines{
		.note{
			font-size:14px;
			margin-bottom:20px;
		}
		margin-top:20px;
		padding:20px;
		background-color:#efefef;
		text-align:center;
		h2{
			font-weight:normal;
			font-size:18px;
			margin:0;
			margin-bottom:20px;
			
		}
		.grid-header{
			font-weight:bold;
			text-align:left;
			font-size:14px;
		}
		.wines-list{

			margin:0;
			padding:0;
			list-style:none;
			margin-top:10px;
			margin-bottom:20px;
			border-top:solid 1px #ccc;
			li{
				padding:5px;
				border-bottom:solid 1px #ccc;
				text-align:left;
			}
			.MuiSelect-outlined.MuiSelect-outlined, .MuiInputBase-input {
				background-color:#fff;
			}
		}
	}
}