.my-account-form {
	max-width:1200px;
	margin:auto;
	.error{
		margin-top:20px;
		margin-bottom:20px;
		background-color:#900;
		padding:10px;
		color:#fff;

	}
}
