.tasting-edit{
	.reject-btn {
		width:100%;
	}

}
.tasting-header-left{
	h1{
		text-align:left;
		margin-left:20px;
	}
}
.tasting-header-right{
	img{
		width:100%;
		height:auto;
	}
}

.MuiDialogActions-root.center{
	align-items: center;
    justify-content: center;
}
.dashboard h2.table-name{
	text-align:left;
	margin-bottom:20px;

}
.dashboard .tasting-container:first-child h2.table-name{
	position:relative;
	top:-20px;
	margin-bottom:0px;
}
.rejected-message{
	padding:20px;
	text-align:center;
	.rejection-message{
		padding-bottom:20px;
	}
}

.flight-group-header {
	font-weight:bold;
	text-transform:uppercase;
	margin-bottom:20px;
}

.tastings-wrapper{ 
	
	.tastings{
		
		.flight-tasting{
			background-color:#efefef;
			height:100%;
			width:100%;
			padding:20px 10px;
			transition: all 0.5s ease;
			cursor:pointer;
			position:relative;
			z-index:20;
		}
		.flight-name{
			color:#333;
			font-weight:bold;
			font-size:18px;
			transition: all 0.5s ease;
			position:relative;
				z-index:20;
		}
		.group-name{
			color:#333;
			font-size:18px !important;
			transition: all 0.5s ease;
			text-transform:uppercase;
			position:relative;
				z-index:20;
		}
		&.in-progress, &.confirmed {
			.flight-tasting{
				background-color:#d59f5e;
				height:100%;
				width:100%;
				padding:20px 10px;
				transition: all 0.5s ease;
				cursor:pointer;
				.flight-name{
					color:#333;
				}
				.group-name{
					color:#333;
				}
				.status-icon{
					display:none;
				}
			}
			&:hover{
				.flight-name{
					color:#fff;
				}
				.group-name{
					color:#fff;
				}
			}
			position:relative;
			.status-icon {
				position:absolute;
				height:100%;
				width:100%;
				height: 100%;
			    width: 100%;
			    left: 0;
			    top: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index:10;
				.far, .fas {
					opacity: 0.4;
					font-size:60px;
					display:block;
					color:#fff;
				}
			}
		}
		&.confirmed, &.confirmed:hover { 
			.flight-tasting{
				background-color:#00a54f;
				.flight-name{
					color:#fff;
					font-weight:bold;
					font-size:18px;
				}
				.group-name{
					color:#fff;
					font-size:14px;
				}
			}
			.status-icon{
				display:none;
			}
		}

		&.rejected, &.rejected:hover { 
			.flight-tasting{
				background-color:#990000;
				.flight-name{
					color:#fff;
					font-weight:bold;
					font-size:18px;
				}
				.group-name{
					color:#fff;
					font-size:14px;
				}
			}
			.status-icon{
				display:none;
			}
		}
		&:hover{
			.flight-tasting{
				background-color:#375ba4;
				height:100%;
				width:100%;
				padding:20px 10px;

			}
			.flight-name{
				color:#fff;
				font-weight:bold;
				font-size:18px;
			}
			.group-name{
				color:#fff;
				font-size:14px;
			}
		}
	}
}
.tasting-container {
	width:100%;
	padding:0px 20px;
	margin-bottom:40px;
	h2{
		text-transform:uppercase;
		color:#375ba4;
		margin:0px;
	}
	.note{
		font-size:16px;
		color:#333;
	}
}
.dashboard {
	h1 {
		margin-bottom:0px;
		text-transform:uppercase;
		color:#333;
	}
	h2{
		color:#333;
	}
}
.tasting-notes textarea{
	border:none;
	width:100%;
	border:solid 1px #ccc;
	padding:10px;
	font-size:14px;
	&:focus,&:active {
	   outline: none !important;
	   box-shadow: none;
	}
}
.tasting-notes-value{
	color:#375ba4;
	padding: 10px;
	border-top: solid 1px #ccc;
	border-bottom: solid 1px #ccc;
}
.questions {
	h1{
		color:#333;
	}
	h2{
		text-align:left;
		font-weight:bold;
	}
	.form-row {
		h2 {
			margin-top:20px;
		}
		.MuiFormLabel-root{
			margin-left:5px;
			margin-top:10px;
		}
	}
	.questionItem{
		margin-top:10px;
	}
	.MuiFormControl-root{
		width:100%;
		display:block;
	}
	.MuiFormGroup-root{
		display:block;
	}
	.MuiFormControlLabel-root{
		width:20%;
		
		margin:0px;
		text-align:center;
		padding:5px;


	}
	

	.MuiIconButton-root{
		display:none;
	}
	.MuiTypography-root {
	 	width:100%;
	 	text-align:center;
	 	display:block;
	 	background-color:#e9eaea;
		color:#3256a2;
		padding:10px 20px;
	}
	.Mui-checked .MuiTypography-root {
		background-color:#b0ca6b;
		color:#3256a2;
	}
	.active .MuiTypography-root {
		background-color:#b0ca6b;
		color:#3256a2;
	}
}
.result-group{
	margin-top:20px;
	margin-bottom:20px;
	padding:1px;
}
.result-header {
	font-weight:normal;
	text-transform:uppercase;
	color:#375ba4;
	font-size:22px;
	margin:10px 0px;

}
.results-content {
	margin-top:20px;
	padding-bottom:20px;
}
.result-group {
	.result:last-child {
		border-bottom:solid 1px #ccc;
	}
}

.result, .result-total {
	font-size:20px;
	border-top:solid 1px #ccc;

	.value-header {
		color:#375ba4;
		.wrapper {
			padding:10px;
		}
	}
	.value-score{
		text-align:center;
		color:#375ba4;
		.wrapper {
			padding:10px;
		}
	}
}

.result-total{
	border:none;
	font-weight:bold;
	font-size:22px;
	.value-header {
		color:#375ba4;
		.wrapper {
			padding:10px;
			text-align:right;
		}
	}
	.result,.result:last-child {
		border:none;
	}
}