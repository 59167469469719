.btn {
	display:inline-block;
	padding:10px 40px;
	background-color:$primaryColor;
	color:#fff;
	font-size:20px;
	text-decoration:none;
	text-transform:uppercase;
}

.login-btn {
	width:100%;
	
	color:#fff;
}
