.reports { 
	.avg-label{
		font-weight:bold;
		.meta{
			font-size:11px;
			font-weight:normal;
		}
	}
	.avg-value{
		font-size:40px;
	}
	.avg-item {
		background-color:#efefef;
		padding:15px;
		min-width:200px;
		margin-right:10px;
		margin-top:10px;
		width:45%;
	}
	h2 {
		text-align:left;
	}
	.report-section {
		margin-bottom:20px;
		.report-section-inner {
			width:100%;
			display:inline-block;
			&.block {
				display:block;
				.submissions {
					max-height:500px;
				}
			}
			border:solid 2px #ccc;
			padding:10px;
		}
	}
	.pie-label {
		font-size:16px;
		text-transform:uppercase;
	}
}